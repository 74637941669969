<template>
  <div class="AppInfoForm-wrapper">
    <form-panel ref="formPanel"
                :form="form"
                v-bind="submitConfig"
                :submitBefore="submitBefore"
                :submitSuccess="submitSuccess"
                @update="update">
      <col2-detail>
        <col2-block title="">
          <el-form-item label="商店更新地址"
                        :rules="[{ required: true, message: '请输入商店更新地址', trigger: 'change'}]"
                        prop="resourceUrl"
          >
            <v-input v-model="form.resourceUrl"
                     placeholder="最多200"
                     :maxlength="200"
                     :width="400"
                     :disabled="!editable"
                     />
          </el-form-item>
          <el-form-item label="线上版本号">
            <v-input v-model="form.onlineAppVersion"
                     :disabled="onlineDisable"/>
          </el-form-item>
        <el-form-item label="当前版本号"
                      :rules="[{ required: true, message: '请输入当前版本号', trigger: 'change'}]"
                      prop="appVersion"
        >
          <v-input v-model="form.appVersion"
                   :disabled="!editable"
                   placeholder="请输入" />
          <label v-if="form.channelType === 1" class="tips">注：请填写如20、30这样的版本号</label>
        </el-form-item>
        <el-form-item label="更新文案"
                      :rules="[{ required: true, message: '请输入更新文案', trigger: 'change'}]"
                      prop="content">
          <v-textarea v-model="form.content"
                      placeholder="最多80个字"
                      :disabled="!editable"
                      :maxlength="80" />
        </el-form-item>
        <el-form-item label="更新类型"
                      :rules="[{ required: true, message: '请选择更新类型', trigger: 'change' }]"
                      prop="isForce">
          <v-select v-model="form.isForce"
                    :disabled="!editable"
                    :options="isForceOps" />
        </el-form-item>
        <span v-if="form.isForce === 0">
          <el-form-item label="最低兼容版本"
                        :rules="[{ required: true, message: '请输入最低兼容版本', trigger: 'change'}]"
                        prop="minVersion">
            <v-input v-model="form.minVersion"
                     :disabled="!editable"
                     placeholder="请输入" />
          </el-form-item>
          <el-form-item label="兼容更新文案"
                        :rules="[{ required: true, message: '请输入兼容更新文案', trigger: 'change'}]"
                        prop="minVersionContent">
            <v-textarea v-model="form.minVersionContent"
                        placeholder="最多80个字"
                        :disabled="!editable"
                        :maxlength="80" />
          </el-form-item>
        </span>
        </col2-block>
      </col2-detail>
      <template #footerSlot="scope">
        <v-button  text="保存" class="footer-button-save"  type="success" @click="save" v-if="editable"/>
        <v-button  text="发布" class="footer-button-publish"  @click="publish"  v-if="editable"/>
        <v-button  text="编辑" class="footer-button-save"  type="success" @click="editPage" v-if="!editable"/>
      </template>
    </form-panel>
  </div>
</template>

<script>

import { saveAppInfoURL, publishAppInfoURL } from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'
import { setAppTypeOps, setChannelTypeOps, setIsForceOps, appTypeMap, channelTypeMap } from './map'
import _ from 'lodash'

export default {
  name: 'IOSStoreCell',
  components: {
    Col2Block,
    Col2Detail
  },
  props: {
    data: Object
  },
  data () {
    return {
      width: 500,
      pageType: 0,
      rowId: '',
      resUrl: '',
      editable: false,
      onlineDisable: true,
      isPublish: false,
      submitConfig: {
        queryUrl: '',
        submitUrl: ''
      },
      appTypeMap,
      appTypeOps: setAppTypeOps(),
      isForceOps: setIsForceOps(),
      channelTypeOps: [],
      computedChannelTypeOps: [],
      form: {
        id: undefined,
        appUniqueCode: '',
        appVersion: '',
        channelType: undefined,
        content: '',
        isForce: undefined,
        minVersion: '',
        minVersionContent: '',
        onlineAppVersion: '',
        onlineMinVersion: '',
        resourceUrl: ''
      }
    }
  },
  created () {
    this.setForm(this.data)
    const channelTypeOps = setChannelTypeOps().map(item => {
      return {
        label: item.text,
        value: item.value
      }
    })
    this.channelTypeOps = channelTypeOps
    this.computedChannelTypeOps = channelTypeOps
  },
  mounted () {},
  methods: {
    appTypeChange (val) {
      let channelTypeOps = _.cloneDeep(this.channelTypeOps) || []
      let channelType = this.form.channelType || []
      if (val === 4) {
        this.form.channelType = channelType.filter(it => {
          return it === 1
        })
        this.computedChannelTypeOps = channelTypeOps.filter((item, index) => {
          return item.value === 1
        })
        this.computedChannelTypeOps.forEach((item, index) => {
          if (item.value !== 1) {
            this.channelTypeChange(index, this.form.channelType)
          }
        })
      } else {
        this.computedChannelTypeOps = this.channelTypeOps
      }
    },
    publish () {
      this.isPublish = true
      this.submitConfig.submitUrl = publishAppInfoURL
      this.$refs.formPanel.submitClick()
    },
    save () {
      this.isPublish = false
      this.submitConfig.submitUrl = saveAppInfoURL
      this.$refs.formPanel.submitClick()
    },
    editPage () {
      this.editable = !this.editable
    },
    isNewItem (item) {
      return item.hasOwnProperty('newItem')
    },
    setForm (remoteData) {
      this.rowId = remoteData.sysId
      this.form.appUniqueCode = remoteData.appUniqueCode
      this.form.appVersion = remoteData.appVersion
      this.form.channelType = remoteData.channelType
      this.form.content = remoteData.content
      this.form.minVersion = remoteData.minVersion
      this.form.minVersionContent = remoteData.minVersionContent
      this.form.onlineAppVersion = remoteData.onlineAppVersion
      this.form.onlineMinVersion = remoteData.onlineMinVersion
      this.form.resourceUrl = remoteData.resourceUrl
      this.form.isForce = remoteData.isForce
    },
    isAppType (type) {
      return type === 1 || type === 2 || type === 3
    },
    update (data) {
      if (data && data.list && data.list.length) {
        data['channelType'] = []
        data.list.forEach(item => {
          const channelType = this.computedChannelTypeOps.find(channelTypeOp => {
            return channelTypeOp.value === item.channelType
          })
          if (channelType) {
            channelType.disabled = true
          }
          data['channelType'].push(item.channelType)
        })
      }
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      this.appTypeChange(this.form.appType)
    },
    async submitBefore (data) {
      data.sysAppInfoId = this.rowId
      if (this.isPublish) {
        const result = await this.$confirm('修改APP配置信息后，将会影响用户使用和下载，请谨慎操作。')
        if (!result) {
          return false
        }
      }
      return true
    },
    submitSuccess (data, msg) {
      this.editPage()
      if (this.isPublish) {
        this.$message.success('发布成功')
        this.form.onlineAppVersion = this.form.appVersion
      } else {
        this.$message.success('保存成功')
      }
      return false
    },
    getTitle (channelType) {
      return channelTypeMap[channelType]
    },
    channelTypeChange (index, ops) {
      const value = this.channelTypeOps[index].value
      if (ops.indexOf(value) === -1) {
        const i = this.form.list.findIndex(item => {
          return item.channelType === value
        })
        this.form.list.splice(i, 1)
      } else {
        this.form.list.push({
          newItem: undefined,
          channelType: value,
          id: '',
          appId: undefined,
          keyName: '',
          content: '',
          isForce: this.isForceOps[0].value,
          minVersion: '',
          minVersionContent: '',
          resourceUrl: ''
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .AppInfoForm-wrapper {
    .text {
      display: inline-block;
      min-width: 144px;
    }
    .tips {
      margin-left: 20px;
    }
    .title {
      width: 742px;
      background-color: #e3e8ef;
      padding: 5px 0 5px 5px;
      margin-bottom: 20px;
    }
    .footer-button-save {
      margin-bottom: 20px;
    }
    .footer-button-publish {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
</style>
