import { mapHelper } from 'common/utils'

// APP类型
const appType = [
  {
    text: '用户端',
    value: 1
  },
  {
    text: '服务端',
    value: 2
  },
  {
    text: '商家端',
    value: 3
  },
  {
    text: '访客机',
    value: 4
  }
]

const {
  map: appTypeMap,
  setOps: setAppTypeOps
} = mapHelper.setMap(appType)

// 渠道类型
const channelType = [
  {
    text: 'Android',
    value: 1
  },
  {
    text: 'iOS-商店',
    value: 2
  },
  {
    text: 'iOS-企业',
    value: 3
  },
  {
    text: '微信小程序',
    value: 4
  },
  {
    text: '支付宝小程序',
    value: 5
  },
  {
    text: 'H5',
    value: 6
  }
]

const {
  map: channelTypeMap,
  setOps: setChannelTypeOps
} = mapHelper.setMap(channelType)

// 更新类型
const isForce = [
  {
    text: '强制更新',
    value: 1
  },
  {
    text: '普通更新',
    value: 0
  }
]

const {
  map: isForceMap,
  setOps: setIsForceOps
} = mapHelper.setMap(isForce)

export {
  appTypeMap,
  setAppTypeOps,
  channelTypeMap,
  setChannelTypeOps,
  isForceMap,
  setIsForceOps
}
