<template>
  <div ref="tabs" class='orgManageForm-container'>
    <tabs-panel
      v-if="tabs.length"
      :tabRouter="false"
      :tabs="tabs"
      :activeLabel="activeLabel"
      @change="changeTab"
    />
  </div>
</template>
<script>

import { TabsPanel } from 'components/bussiness'
import androidCell from './AndroidCell'
import iOSStoreCell from './IOSStoreCell'
import iOSEnterpriseCell from './IOSEnterpriseCell'
import wechatCell from './WechatCell'
import aliPayCell from './AliPayCell'
import h5Cell from './H5Cell'
import { getAppTabsInfoURL } from './api'

export default {
  name: 'UpdateConfigForm',
  components: {
    TabsPanel
  },

  data () {
    return {
      centerType: 1,
      activeLabel: '',
      orgInfo: {
        data: {},
        isEdit: false
      },
      tabs: [],
      channelTypes: [],
      disabled1: true,
      disabled2: true,
      tabData: {
        id: '',
        channelTypes: [],
        list: []
      }
    }
  },
  mounted () {
    const { id } = this.$route.query
    this.getTabsInfo(id)
  },
  methods: {
    changeTab (index) {
      this.activeLabel = this.getAppTypeText(this.channelTypes[index])
    },
    getComponentByType (cType) {
      if (cType === 1) {
        return androidCell
      } else if (cType === 2) {
        return iOSStoreCell
      } else if (cType === 3) {
        return iOSEnterpriseCell
      } else if (cType === 4) {
        return wechatCell
      } else if (cType === 5) {
        return aliPayCell
      } else if (cType === 6) {
        return h5Cell
      }
    },
    getAppTypeText (cType) {
      if (cType === 1) {
        return 'Android'
      } else if (cType === 2) {
        return 'iOS-商店'
      } else if (cType === 3) {
        return 'iOS-企业'
      } else if (cType === 4) {
        return '微信小程序'
      } else if (cType === 5) {
        return '支付宝小程序'
      } else if (cType === 6) {
        return 'H5'
      }
    },
    getTabsInfo (id) {
      let _this = this
      _this.$axios(`${getAppTabsInfoURL}?id=${id}`).then(res => {
        if (res.status === 100) {
          _this.channelTypes = res.data.channelTypes
          res.data.list.forEach(item => {
            item.sysId = id
            const tab = {
              label: _this.getAppTypeText(item.channelType),
              component: _this.getComponentByType(item.channelType),
              props: { data: item }
            }
            _this.tabs.push(tab)
          })
        }
      })
    }
  }
}
</script>
