<template>
  <div class="AppInfoForm-wrapper">
    <form-panel ref="formPanel"
                :form="form"
                v-bind="submitConfig"
                :submitBefore="submitBefore"
                :submitSuccess="submitSuccess"
                @update="update">
      <col2-detail>
        <col2-block title="">
        <el-form-item label="APP名称">
          <div class="text" v-text="this.configAppName"></div>
        </el-form-item>
        <el-form-item label="64位"
                      :rules="[{ required: true, message: '请上传apk文件', trigger: 'change' }]"
                      prop="resourceUrl">
          <v-uploader :action="uploadURL"
                      :fileList.sync="form.resourceUrl"
                      :onlyImage="false"
                      :limit="1"
                      :acceptFileType="supportFileType"
                      :fileWidth="100"
                      :disabled="!editable"
                      :beforeUpload="beforeUpload"
                      :onSuccess="onSuccess"
                      :onError="onError"
                      :fileSize="200">
            <template #tip>
              <label>注：请上传64位apk文件,文件需要小于200M</label>
            </template>
          </v-uploader>
          <div v-if="getFileUrl">当前资源地址：{{getFileUrl}}</div>
        </el-form-item>
        <el-form-item label="32位">
          <v-uploader :action="uploadURL"
                      :fileList.sync="form.resourceUrlTt"
                      :onlyImage="false"
                      :limit="1"
                      :acceptFileType="supportFileType"
                      :fileWidth="100"
                      :disabled="!editable"
                      :beforeUpload="beforeUpload"
                      :onSuccess="onSuccess"
                      :onError="onError"
                      :fileSize="200">
            <template #tip>
              <label>注：请上传32位apk文件,文件需要小于200M</label>
            </template>
          </v-uploader>
          <div v-if="getFileUrl2">当前资源地址：{{getFileUrl2}}</div>
        </el-form-item>
        <el-form-item label="X86虚拟机">
          <v-uploader :action="uploadURL"
                      :fileList.sync="form.resourceUrlSf"
                      :onlyImage="false"
                      :limit="1"
                      :acceptFileType="supportFileType"
                      :fileWidth="100"
                      :disabled="!editable"
                      :beforeUpload="beforeUpload"
                      :onSuccess="onSuccess"
                      :onError="onError"
                      :fileSize="200">
            <template #tip>
              <label>注：请上传X86位apk文件,仅用于模拟器，文件需要小于200M</label>
            </template>
          </v-uploader>
          <div v-if="getFileUrl3">当前资源地址：{{getFileUrl3}}</div>
        </el-form-item>
          <el-form-item label="线上版本Code">
            <div class="text" v-text="form.onlineAppVersion"></div>
          </el-form-item>
        <el-form-item label="当前版本Code"
                      :rules="[{ required: true, message: '请输入当前版本号', trigger: 'change'}]"
                      prop="appVersion"
        >
          <v-input v-model="form.appVersion"
                   :disabled="!editable"
                   placeholder="请输入" />
        </el-form-item>
        <el-form-item label="更新文案"
                      :rules="[{ required: true, message: '请输入更新文案', trigger: 'change'}]"
                      prop="content">
          <v-textarea v-model="form.content"
                      placeholder="最多80个字"
                      :disabled="!editable"
                      :maxlength="80" />
        </el-form-item>
        <el-form-item label="更新类型"
                      :rules="[{ required: true, message: '请选择更新类型', trigger: 'change' }]"
                      prop="isForce">
          <v-select v-model="form.isForce"
                    :options="isForceOps"
                    :disabled="!editable"/>
        </el-form-item>
        <span v-if="form.isForce === 0">
          <el-form-item label="最低兼容版本"
                        :rules="[{ required: true, message: '请输入最低兼容版本', trigger: 'change'}]"
                        prop="minVersion">
            <v-input v-model="form.minVersion"
                     placeholder="请输入"
                     :disabled="!editable"/>
          </el-form-item>
          <el-form-item label="兼容更新文案"
                        :rules="[{ required: true, message: '请输入兼容更新文案', trigger: 'change'}]"
                        prop="minVersionContent">
            <v-textarea v-model="form.minVersionContent"
                        placeholder="最多80个字"
                        :maxlength="80"
                        :disabled="!editable"/>
          </el-form-item>
        </span>
        </col2-block>
      </col2-detail>
      <template #footerSlot="scope">
        <v-button  text="保存" class="footer-button-save"  type="success" @click="save" v-if="editable"/>
        <v-button  text="发布" class="footer-button-publish"  @click="publish" v-if="editable"/>
        <v-button  text="编辑" class="footer-button-save"  type="success" @click="editPage" v-if="!editable"/>
      </template>
    </form-panel>
  </div>
</template>

<script>
import { uploadURL } from 'common/api'
import { saveAppInfoURL, publishAppInfoURL } from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'
import { vUploader } from 'components/control'
import { setAppTypeOps, setChannelTypeOps, setIsForceOps, appTypeMap, channelTypeMap } from './map'
import _ from 'lodash'

export default {
  name: 'AppUpdateCell',
  components: {
    Col2Block,
    Col2Detail,
    vUploader
  },
  props: {
    data: Object
  },
  computed: {
    getFileUrl () {
      if (typeof (this.form.resourceUrl) === 'string') {
        return this.form.resourceUrl
      } else if (typeof (this.form.resourceUrl) === 'object' && this.form.resourceUrl.length) {
        return this.form.resourceUrl[0].url
      } else {
        return ''
      }
    },
    getFileUrl2 () {
      console.log(this.form.resourceUrlTt,'this.form.resourceUrlTtthis.form.resourceUrlTt')
      if (typeof (this.form.resourceUrlTt) === 'string') {
        return this.form.resourceUrlTt
      } else if (typeof (this.form.resourceUrlTt) === 'object' && this.form.resourceUrlTt.length) {
        return this.form.resourceUrlTt[0].url
      } else {
        return ''
      }
    },
    getFileUrl3 () {
      if (typeof (this.form.resourceUrlSf) === 'string') {
        return this.form.resourceUrlSf
      } else if (typeof (this.form.resourceUrlSf) === 'object'&& this.form.resourceUrlSf.length) {
        return this.form.resourceUrlSf[0].url
      } else {
        return ''
      }
    }
  },
  data () {
    return {
      width: 500,
      pageType: 0,
      supportFileType: '.apk',
      editable: false,
      isUploadLoading: false,
      configAppName: '',
      rowId: '',
      resUrl: '',
      isPublish: false,
      submitConfig: {
        queryUrl: '',
        submitUrl: ''
      },
      uploadURL: `${uploadURL}?module=app`,
      appTypeMap,
      appTypeOps: setAppTypeOps(),
      isForceOps: setIsForceOps(),
      channelTypeOps: [],
      computedChannelTypeOps: [],
      form: {
        id: undefined,
        appUniqueCode: '',
        appVersion: '',
        channelType: undefined,
        content: '',
        isForce: undefined,
        minVersion: '',
        minVersionContent: '',
        onlineAppVersion: '',
        onlineMinVersion: '',
        resourceUrl: '',
        resourceUrlTt: '',
        resourceUrlSf: ''
      }
    }
  },
  created () {
    this.setForm(this.data)
    const channelTypeOps = setChannelTypeOps().map(item => {
      return {
        label: item.text,
        value: item.value
      }
    })
    this.channelTypeOps = channelTypeOps
    this.computedChannelTypeOps = channelTypeOps
  },
  mounted () {
    const { appName } = this.$route.query
    this.configAppName = appName
  },
  methods: {
    appTypeChange (val) {
      let channelTypeOps = _.cloneDeep(this.channelTypeOps) || []
      let channelType = this.form.channelType || []
      if (val === 4) {
        this.form.channelType = channelType.filter(it => {
          return it === 1
        })
        this.computedChannelTypeOps = channelTypeOps.filter((item, index) => {
          return item.value === 1
        })
        this.computedChannelTypeOps.forEach((item, index) => {
          if (item.value !== 1) {
            this.channelTypeChange(index, this.form.channelType)
          }
        })
      } else {
        this.computedChannelTypeOps = this.channelTypeOps
      }
    },
    publish () {
      if (this.isUploadLoading) {
        this.$message(`文件上传中，请稍后`)
      } else {
        this.isPublish = true
        this.submitConfig.submitUrl = publishAppInfoURL
        this.$refs.formPanel.submitClick()
      }
    },
    save () {
      if (this.isUploadLoading) {
        this.$message(`文件上传中，请稍后`)
      } else {
        this.isPublish = false
        this.submitConfig.submitUrl = saveAppInfoURL
        this.$refs.formPanel.submitClick()
      }
    },
    editPage () {
      this.editable = !this.editable
    },
    onSuccess () {
      // 记录上传状态 解决文件上传中点保存bug
      this.isUploadLoading = false
    },
    onError () {
      // 记录上传状态
      this.isUploadLoading = false
    },
    beforeUpload () {
      if (!this.editable) {
        this.$message(`当前状态不可编辑`)
        return false
      } else {
        // 记录上传状态
        this.isUploadLoading = true
        return true
      }
    },
    isNewItem (item) {
      return item.hasOwnProperty('newItem')
    },
    setForm (remoteData) {
      this.rowId = remoteData.sysId
      this.resUrl = remoteData.resourceUrl
      this.form.appUniqueCode = remoteData.appUniqueCode
      this.form.appVersion = remoteData.appVersion
      this.form.channelType = remoteData.channelType
      this.form.content = remoteData.content
      this.form.minVersion = remoteData.minVersion
      this.form.minVersionContent = remoteData.minVersionContent
      this.form.onlineAppVersion = remoteData.onlineAppVersion
      this.form.onlineMinVersion = remoteData.onlineMinVersion
      this.form.resourceUrl = remoteData.resourceUrl
      this.form.resourceUrlTt = remoteData.resourceUrlTt
      this.form.resourceUrlSf = remoteData.resourceUrlSf
      this.form.isForce = remoteData.isForce
    },
    update (data) {
      if (data && data.list && data.list.length) {
        data['channelType'] = []
        data.list.forEach(item => {
          const channelType = this.computedChannelTypeOps.find(channelTypeOp => {
            return channelTypeOp.value === item.channelType
          })
          if (channelType) {
            channelType.disabled = true
          }
          data['channelType'].push(item.channelType)
        })
      }
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      this.appTypeChange(this.form.appType)
    },
    async submitBefore (data) {
      data.resourceUrl = this.getFileUrl
      data.resourceUrlTt = this.getFileUrl2
      data.resourceUrlSf = this.getFileUrl3
      data.sysAppInfoId = this.rowId
      if (data.resourceUrl.length < 0) {
        this.$message.error('APP资源地址不存在，请重新上传')
        return false
      }
      if (this.isPublish) {
        const result = await this.$confirm('修改APP配置信息后，将会影响用户使用和下载，请谨慎操作。')
        if (!result) {
          return false
        }
      }
      return true
    },
    submitSuccess (data, msg) {
      this.editPage()
      if (this.isPublish) {
        this.$message.success('发布成功')
        this.form.onlineAppVersion = this.form.appVersion
      } else {
        this.$message.success('保存成功')
      }
      return false
    },
    getTitle (channelType) {
      return channelTypeMap[channelType]
    },
    channelTypeChange (index, ops) {
      const value = this.channelTypeOps[index].value
      if (ops.indexOf(value) === -1) {
        const i = this.form.list.findIndex(item => {
          return item.channelType === value
        })
        this.form.list.splice(i, 1)
      } else {
        this.form.list.push({
          newItem: undefined,
          channelType: value,
          id: '',
          appId: undefined,
          keyName: '',
          content: '',
          isForce: this.isForceOps[0].value,
          minVersion: '',
          minVersionContent: '',
          resourceUrl: '',
          resourceUrlTt: '',
          resourceUrlSf: ''
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .AppInfoForm-wrapper {
    .text {
      display: inline-block;
      min-width: 144px;
    }
    .tips {
      margin-left: 20px;
    }
    .title {
      width: 742px;
      background-color: #e3e8ef;
      padding: 5px 0 5px 5px;
      margin-bottom: 20px;
    }
    .footer-button-save {
      margin-bottom: 20px;
    }
    .footer-button-publish {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
</style>
