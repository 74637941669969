var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AppInfoForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "当前版本号",
                        rules: [
                          {
                            required: true,
                            message: "请输入当前版本号",
                            trigger: "change",
                          },
                        ],
                        prop: "appVersion",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.form.appVersion,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "appVersion", $$v)
                          },
                          expression: "form.appVersion",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "更新文案",
                        rules: [
                          {
                            required: true,
                            message: "请输入更新文案",
                            trigger: "change",
                          },
                        ],
                        prop: "content",
                      },
                    },
                    [
                      _c("v-textarea", {
                        attrs: { placeholder: "最多80个字", maxlength: 80 },
                        model: {
                          value: _vm.form.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "content", $$v)
                          },
                          expression: "form.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }