var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AppInfoForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "footerSlot",
                fn: function (scope) {
                  return [
                    _vm.editable
                      ? _c("v-button", {
                          staticClass: "footer-button-save",
                          attrs: { text: "保存", type: "success" },
                          on: { click: _vm.save },
                        })
                      : _vm._e(),
                    _vm.editable
                      ? _c("v-button", {
                          staticClass: "footer-button-publish",
                          attrs: { text: "发布" },
                          on: { click: _vm.publish },
                        })
                      : _vm._e(),
                    !_vm.editable
                      ? _c("v-button", {
                          staticClass: "footer-button-save",
                          attrs: { text: "编辑", type: "success" },
                          on: { click: _vm.editPage },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "商店更新地址",
                        rules: [
                          {
                            required: true,
                            message: "请输入商店更新地址",
                            trigger: "change",
                          },
                        ],
                        prop: "resourceUrl",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "最多200",
                          maxlength: 200,
                          width: 400,
                          disabled: !_vm.editable,
                        },
                        model: {
                          value: _vm.form.resourceUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "resourceUrl", $$v)
                          },
                          expression: "form.resourceUrl",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "线上版本号" } },
                    [
                      _c("v-input", {
                        attrs: { disabled: _vm.onlineDisable },
                        model: {
                          value: _vm.form.onlineAppVersion,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "onlineAppVersion", $$v)
                          },
                          expression: "form.onlineAppVersion",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "当前版本号",
                        rules: [
                          {
                            required: true,
                            message: "请输入当前版本号",
                            trigger: "change",
                          },
                        ],
                        prop: "appVersion",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          disabled: !_vm.editable,
                          placeholder: "请输入",
                        },
                        model: {
                          value: _vm.form.appVersion,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "appVersion", $$v)
                          },
                          expression: "form.appVersion",
                        },
                      }),
                      _vm.form.channelType === 1
                        ? _c("label", { staticClass: "tips" }, [
                            _vm._v("注：请填写如20、30这样的版本号"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "更新文案",
                        rules: [
                          {
                            required: true,
                            message: "请输入更新文案",
                            trigger: "change",
                          },
                        ],
                        prop: "content",
                      },
                    },
                    [
                      _c("v-textarea", {
                        attrs: {
                          placeholder: "最多80个字",
                          disabled: !_vm.editable,
                          maxlength: 80,
                        },
                        model: {
                          value: _vm.form.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "content", $$v)
                          },
                          expression: "form.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "更新类型",
                        rules: [
                          {
                            required: true,
                            message: "请选择更新类型",
                            trigger: "change",
                          },
                        ],
                        prop: "isForce",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          disabled: !_vm.editable,
                          options: _vm.isForceOps,
                        },
                        model: {
                          value: _vm.form.isForce,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isForce", $$v)
                          },
                          expression: "form.isForce",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.isForce === 0
                    ? _c(
                        "span",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最低兼容版本",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入最低兼容版本",
                                    trigger: "change",
                                  },
                                ],
                                prop: "minVersion",
                              },
                            },
                            [
                              _c("v-input", {
                                attrs: {
                                  disabled: !_vm.editable,
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.form.minVersion,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "minVersion", $$v)
                                  },
                                  expression: "form.minVersion",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "兼容更新文案",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入兼容更新文案",
                                    trigger: "change",
                                  },
                                ],
                                prop: "minVersionContent",
                              },
                            },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  placeholder: "最多80个字",
                                  disabled: !_vm.editable,
                                  maxlength: 80,
                                },
                                model: {
                                  value: _vm.form.minVersionContent,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "minVersionContent", $$v)
                                  },
                                  expression: "form.minVersionContent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }