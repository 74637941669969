<template>
  <div class="AppInfoForm-wrapper">
    <form-panel ref="formPanel"
                :form="form"
                v-bind="submitConfig"
                :submitBefore="submitBefore"
                @update="update">
      <col2-detail>
        <col2-block title="">
        <el-form-item label="当前版本号"
                      :rules="[{ required: true, message: '请输入当前版本号', trigger: 'change'}]"
                      prop="appVersion"
        >
          <v-input v-model="form.appVersion"
                   placeholder="请输入" />
        </el-form-item>
        <el-form-item label="更新文案"
                      :rules="[{ required: true, message: '请输入更新文案', trigger: 'change'}]"
                      prop="content">
          <v-textarea v-model="form.content"
                      placeholder="最多80个字"
                      :maxlength="80" />
        </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>

import { publishAppInfoURL } from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'

export default {
  name: 'WechatCell',
  components: {
    Col2Block,
    Col2Detail
  },
  props: {
    data: Object
  },
  data () {
    return {
      width: 500,
      rowId: '',
      submitConfig: {
        queryUrl: '',
        submitUrl: publishAppInfoURL
      },
      form: {
        id: undefined,
        appUniqueCode: '',
        appVersion: '',
        channelType: undefined,
        content: ''
      }
    }
  },
  created () {
    this.setForm(this.data)
  },
  mounted () {},
  methods: {
    setForm (remoteData) {
      this.rowId = remoteData.sysId
      this.form.appVersion = remoteData.appVersion
      this.form.channelType = remoteData.channelType
      this.form.content = remoteData.content
    },
    update (data) {
      if (data && data.list && data.list.length) {
        data['channelType'] = []
        data.list.forEach(item => {
          data['channelType'].push(item.channelType)
        })
      }
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      this.appTypeChange(this.form.appType)
    },
    async submitBefore (data) {
      data.sysAppInfoId = this.rowId
      const result = await this.$confirm('修改APP配置信息后，将会影响用户使用和下载，请谨慎操作。')
      if (!result) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .AppInfoForm-wrapper {
    .text {
      display: inline-block;
      min-width: 144px;
    }
    .tips {
      margin-left: 20px;
    }
    .title {
      width: 742px;
      background-color: #e3e8ef;
      padding: 5px 0 5px 5px;
      margin-bottom: 20px;
    }
    .footer-button {
      margin-bottom: 20px;
      > :nth-child(n) {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
</style>
