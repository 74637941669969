var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "tabs", staticClass: "orgManageForm-container" },
    [
      _vm.tabs.length
        ? _c("tabs-panel", {
            attrs: {
              tabRouter: false,
              tabs: _vm.tabs,
              activeLabel: _vm.activeLabel,
            },
            on: { change: _vm.changeTab },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }